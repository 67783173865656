import React, { useState, useEffect } from 'react';
import cn from 'classnames';

import Button from '../components/button/button';
import Layout from '../components/layout';
import Seo from '../components/seo';
import ReferralProgramModal from '../components/referral-program-modal/referralProgramModal';

import * as s from '../styles/referral-program.module.scss';

import { yaEvent } from '../helper/metrika';

const MENU_LEFT = [
  {
    title: 'Главная',
    value: '/',
    mobile: true
  }
];

const ReferralProgram = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    yaEvent('referral-program_show_page');
  }, []);

  const buttonClickHandler = () => {
    setIsOpen(true);
    yaEvent('referral-program_click_request');
  };

  return (
    <Layout isScroll={true} menuItems={MENU_LEFT} className={s.referralProgram}>
      <Seo
        title="Реферальная программа - СберПодбор - CRM для рекрутеров"
        description="Реферальная программа - посоветуйте СберПодбор знакомым и получите 10% от первого платежа"
        meta={[
          {
            property: 'og:title',
            content: 'Реферальная программа СберПодбора'
          },
          {
            property: 'og:description',
            content: 'Приводите клиентов в сервис и получайте в подарок 10% от стоимости купленных ими тарифов. Подробнее на sberpodbor.ru'
          }
        ]}
      />
      <div className={s.hero}>
        <div className='container'>
          <div className={s.heroContainer}>
            <div className={s.heroLeft}>
              <h1 className={s.heroTitle}>
                Реферальная программа<br/> СберПодбора*
              </h1>
              <p className={s.heroText}>
                Рекомендуйте сервис новым пользователям — получайте
                в подарок 10% от стоимости первого купленного им тарифа
              </p>
              <Button
                text={'Оставить заявку на участие'}
                newWindow
                medium
                onClick={buttonClickHandler}
              />
            </div>
            <img
              className={s.heroIllustration}
              src="/images/referral-program-hero.svg"
              alt={'СберПодбор'}
            />
          </div>
        </div>
      </div>

      <div className={s.benefits}>
        <div className='container'>
          <h2 className={cn('title-2', s.benefitsTitle)}>
            Пригласите в СберПодбор тех, кто нанимает,<br/> — это выгодно всем:
          </h2>
          <div className={s.benefitsContent}>
            <div className={s.benefitsBlock}>
              <h5 className={s.benefitsBlockTitle}>Приглашенные получат:</h5>
              <ul>
                <li className={s.benefitsBlockListItem}>
                  <b className={s.benefitsBlockListItemBold}>удобную CRM**</b> систему для рекрутинга с бесплатным базовым функционалом сразу после регистрации в СберПодборе
                </li>
                <li className={s.benefitsBlockListItem}>
                  <b className={s.benefitsBlockListItemBold}>скидку 10%</b> при оплате тарифов «Стандартный» или «Оптимальный» на полгода или на год
                </li>
              </ul>
            </div>
            <div className={s.benefitsBlock}>
              <h5 className={s.benefitsBlockTitle}>Вы получите:</h5>
              <ul>
                <li className={s.benefitsBlockListItem}>
                  в подарок денежную <b className={s.benefitsBlockListItemBold}>сумму в размере 10%</b> от стоимости тарифа без учета предоставленной скидки, купленного пользователем, который пришел по вашей рекомендации
                </li>
              </ul>
            </div>
          </div>
          <div className={s.benefitsInfo}>
            <p className={s.benefitsInfoTitle}>
              Условия участия в программе***
            </p>
            <p className={s.benefitsText}>
              Рекомендателем может стать любой, кому уже исполнилось 18 лет. Достаточно подать заявку для участия, получить реферальную ссылку и промокод, и начать рекомендовать  СберПодбор. Рекомендатель получает подарок за первую покупку, которую совершит рекомендованный им пользователь.&nbsp;
              <a className="text-blue" href="https://promo.sberpodbor.ru/referral-program" target="_blank" rel="noreferrer">Подробные условия программы</a>
            </p>
          </div>
        </div>
      </div>

      <div className={s.bonus}>
        <div className={cn('container')}>
          <h3 className={s.bonusTitle}>Как получить подарок</h3>
          <div>
            <ul className={s.bonusList}>
              <li className={s.bonusListItem}>
                <h5 className={s.bonusListItemTitle}>Оставьте заявку</h5>
                <p className={s.bonusListItemText}>Мы пришлём вам реферальную ссылку и персональный промокод.</p>
              </li>
              <li className={s.bonusListItem}>
                <h5 className={s.bonusListItemTitle}>Рекомендуйте СберПодбор тем, кто нанимает</h5>
                <p className={s.bonusListItemText}>Если у них еще нет аккаунта в СберПодборе, пришлите им реферальную ссылку и попросите зарегистрироваться по ней. Если есть — отправьте промокод на скидку при покупке платного тарифа, участвующего в Реферальной программе.</p>
              </li>
              <li className={s.bonusListItem}>
                <h5 className={s.bonusListItemTitle}>Расскажите им о скидке</h5>
                <p className={s.bonusListItemText}>Если приглашенные вами пользователи зарегистрировались по реферальной ссылке, они получат скидку 10% при покупке тарифов «Стандартный» или «Оптимальный» на полгода или на год автоматически. Если у них промокод, нужно назвать его менеджеру при оформлении заказа.</p>
              </li>
              <li className={s.bonusListItem}>
                <h5 className={s.bonusListItemTitle}>Дождитесь покупки</h5>
                <p className={s.bonusListItemText}>Приглашенный вами пользователь должен оплатить тариф «Стандартный» или «Оптимальный» на полгода или год и активировать его. После этого мы свяжемся с вами, уточним реквизиты и выплатим денежный подарок.</p>
              </li>
            </ul>
          </div>
          <div className={s.bonusActions}>
            <Button
              className={s.bonusActionsButton}
              text={'Оставить заявку на участие'}
              medium
              onClick={buttonClickHandler}
            />
            <p className={s.bonusActionsText}>
              * Сервис предоставляется ООО «РДВ-софт», ОГРН 5147746474134. Подробнее об условиях и ограничениях на&nbsp;
              <a href="https://sberpodbor.ru" target="_blank" rel="noreferrer" className="text-blue">https://sberpodbor.ru</a><br/>
              **CRM (от англ. «Customer relationship management») — Управление взаимоотношениями с клиентами<br/>
              *** Организатор Реферальной программы ООО «РДВ-софт», ОГРН 5147746474134. <br/>
              С полными условиями участия в Реферальной программе можно ознакомиться по&nbsp;
              <a href="https://promo.sberpodbor.ru/referral-program" target="_blank" className="text-blue" rel="noreferrer">ссылке</a>.<br/>
            </p>
          </div>
        </div>
      </div>
      <ReferralProgramModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </Layout>
  );
};

export default ReferralProgram;
