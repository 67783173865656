import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import Modal from '../modal/modal';
import Button from '../button/button';

import { usePhoneField } from '../../helper/hooks';

import * as s from './referralProgramModal.module.scss';

import { yaEvent } from '../../helper/metrika';

const ReferralProgramModal = props => {
  const { setIsOpen } = props;

  const [isLoading, setIsLoading] = useState(false);

  const phonePattern = new RegExp(/\+[0-9] \([0-9]{3}\) [0-9]{3} [0-9]{2} [0-9]{2}/);
  const { normalizePhone } = usePhoneField();

  useEffect(() => {
    const input = document.querySelector('input[type="phone"]');
    if (input) {
      input.addEventListener('invalid', ({ target }) => {
        const error = target.validity.patternMismatch
          ? 'Неверный формат телефона'
          : '';
        target.setCustomValidity(error);
      });
    }
  }, []);

  const [submit, setSubmit] = useState(false);

  const initialFormValues = {
    name: '',
    lastName: '',
    phone: '',
    email: ''
  };

  const [formValues, setFormValues] = useState({ ...initialFormValues });

  const handleChange = e => {
    const { name, value } = e.target;
    setFormValues(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async e => {
    e.preventDefault();

    setIsLoading(true);
    yaEvent('referral-program_click_send-request');

    try {
      const apiPath = process.env.GATSBY_API_V2 + 'landing/referrals';
      await fetch(apiPath, {
        method: 'POST',
        mode: 'cors',
        headers: new Headers({ 'content-type': 'application/json' }),
        body: JSON.stringify(formValues)
      });

      setSubmit(true);
      yaEvent('referral-program_show_success-send-request');
    } catch (e) {
      console.error('Ошибка при отправке формы, ', e);
    } finally {
      setIsLoading(false);
    }
  };

  const changePhoneHandler = e => {
    const phone = normalizePhone(e.target.value, formValues.phone);
    setFormValues(prevState => ({
      ...prevState,
      phone
    }));
  };

  const closeModal = () => {
    setIsOpen(false);
    resetForm();
  };

  const resetForm = () => {
    setFormValues({
      ...initialFormValues
    });
    setTimeout(() => {
      setSubmit(false);
    }, 300);
  };

  return (
    <Modal {...props} closeModalCallback={resetForm}>
      {
      !submit ?
      <div>
        <h4 className={s.modalTitle}>Оставьте свои контакты</h4>
        <p className={s.modalText}>
          Мы свяжемся с вами в рабочее время и пришлем индивидуальную ссылку с меткой и промокод. <br/><br/>
          <a  href="https://promo.sberpodbor.ru/referral-program" target="_blank" rel="noreferrer" className="text-blue">
            Подробнее об условиях участия в Реферальной программе
          </a>
        </p>
        <form onSubmit={handleSubmit}>
          <label className={s.formElem}>
            <span className={s.formElemCaption}>Имя</span>
            <input
              name="name"
              value={formValues.name}
              type="text"
              className={s.formElemInput}
              required
              onChange={handleChange}
              disabled={isLoading}
            />
          </label>
          <label className={s.formElem}>
            <span className={s.formElemCaption}>Фамилия</span>
            <input
              name="lastName"
              value={formValues.lastName}
              type="text"
              className={s.formElemInput}
              required
              onChange={handleChange}
              disabled={isLoading}
            />
          </label>
          <label className={s.formElem}>
            <span className={s.formElemCaption}>Электронная почта</span>
            <input
              name="email"
              value={formValues.email}
              type="email"
              className={s.formElemInput}
              required
              onChange={handleChange}
              disabled={isLoading}
            />
          </label>
          <label className={s.formElem}>
            <span className={s.formElemCaption}>Номер телефона</span>
            <input
              name="phone"
              type="phone"
              value={formValues.phone}
              className={s.formElemInput}
              placeholder="+7"
              required
              autoComplete="off"
              pattern={phonePattern.toString().slice(1, -1)}
              onChange={changePhoneHandler}
              disabled={isLoading}
            />
          </label>
          <Button
            type="submit"
            medium
            className={s.formButton}
            text={'Отправить заявку'}
            disabled={isLoading}
          />
          <div className={s.modalPp}>
            Отправляя заявку, вы даете<br />
            <a href="/doc/Политика_обработки_персональных_данных.pdf" target="_blank" className="text-blue">Согласие на обработку персональных данных</a>
          </div>
        </form>
      </div>
      :
      <div className={cn(s.modalScreenSubmit)}>
        <img src="/images/success.svg" className={s.modalScreenSubmitImg} alt="" />
        <h4 className={s.modalTitle}>Заявка отправлена!</h4>
        <p className={s.modalText}>
          В ближайшее время мы пришлем вам индивидуальную ссылку с меткой и промокод
        </p>
        <Button medium className={s.formButton} text={'Закрыть'} onClick={closeModal} />
      </div>
      }

    </Modal>
  );
};

export default ReferralProgramModal;
