// extracted by mini-css-extract-plugin
export var referralProgram = "referral-program-module--referralProgram--Juuts";
export var hero = "referral-program-module--hero--FS2RA";
export var heroContainer = "referral-program-module--heroContainer--MdzuH";
export var heroLeft = "referral-program-module--heroLeft--EgHOE";
export var heroTitle = "referral-program-module--heroTitle--IgzNu";
export var heroText = "referral-program-module--heroText--pnedT";
export var heroIllustration = "referral-program-module--heroIllustration--jOIYC";
export var benefits = "referral-program-module--benefits--FdnJf";
export var benefitsContent = "referral-program-module--benefitsContent--SWAc2";
export var benefitsTitle = "referral-program-module--benefitsTitle--8EqR0";
export var benefitsBlock = "referral-program-module--benefitsBlock--XVVmk";
export var benefitsBlockTitle = "referral-program-module--benefitsBlockTitle--h4JYm";
export var benefitsBlockListItem = "referral-program-module--benefitsBlockListItem--FnjWI";
export var benefitsBlockListItemBold = "referral-program-module--benefitsBlockListItemBold--wo7Uz";
export var benefitsInfo = "referral-program-module--benefitsInfo--VFYvO";
export var benefitsInfoTitle = "referral-program-module--benefitsInfoTitle--lykwh";
export var benefitsText = "referral-program-module--benefitsText--E3Tvh";
export var bonus = "referral-program-module--bonus--BViL3";
export var bonusTitle = "referral-program-module--bonusTitle--83Aat";
export var bonusList = "referral-program-module--bonusList--FjQYK";
export var bonusListItem = "referral-program-module--bonusListItem--pvFyr";
export var bonusListItemTitle = "referral-program-module--bonusListItemTitle--N9sZv";
export var bonusListItemText = "referral-program-module--bonusListItemText--VYxKq";
export var bonusActions = "referral-program-module--bonusActions--bUy5n";
export var bonusActionsButton = "referral-program-module--bonusActionsButton--VP3Ig";
export var bonusActionsText = "referral-program-module--bonusActionsText--SvTHq";